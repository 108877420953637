.tab-base-container {
    padding: 10px;
}

.tab-base-nav-wrapper {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.tab-base-nav-wrapper button {
    padding: 10px;
    border: none;
    background: lightgray;
    cursor: pointer;
}

.tab-base-nav-wrapper button.active {
    background: var(--blue);
    color: white;
}

.tab-base-content-wrapper {
    margin-top: 20px;
}

.tab-base-panel-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
}

.tab-base-panel {
    font-size: 14px;
    color: var(--black);
    background-color: transparent;
    border: 1px solid var(--border);
    font-weight: 400;
    cursor: pointer;
    padding: 5px 10px;

}

.tab-base-panel.active {
    color: var(--blue);
    border-color: var(--blue);
}