.address-card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--border);
    border-radius: 4px;
}

.address-card-header {
    /*background-color: var(--border);*/
    border-bottom: 1px solid var(--border);
    padding: 8px 10px;
}

.default-address-text {
    color: var(--gray-violet);
}

.address-card.default-address {
    border: 1px solid var(--blue);
    box-shadow: 0 0 5px #4262ff85;
}

.address-card-content {
    padding: 8px 10px;
}
.address-card-header .panel{
    display: flex;
    align-items: center;
    gap: 8px;
}
.address-card ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.address-card ul li {
    display: flex;
    gap: 8px;
    justify-content: space-between;
}

.address-card ul strong {
    font-weight: 500;
    white-space: nowrap;


    color: var(--gray-violet);
}

.address-card ul span {
    color: black;
    font-weight: 500;
    text-align: right;
    display: block;
}