.total-wrapper {
    background-color: #fff;
    padding: 24px;
}

.total-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.content-text {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.content-amount {
    color: #f4d842;
    padding: 0 12px;
    font-size: 16px;
    font-weight: 600;
}

.content-btn {
    padding: 10px 10px;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    border-radius: 4px;
    border: none;
    color: #fff;
    background-color: #4262ff;
    cursor: pointer;
    text-decoration: none;
}

@media (max-width: 992px) {
    .nightstands-wrapper.shopingcard-wrapper.modal-page .total-wrapper {
        display: grid !important;
        grid-template-columns: 40% 40% 10%;
        justify-content: space-between;
    }

    .total-content {
        justify-content: center;
        grid-column: 1/4;
    }
}

@media (max-width: 568px) {
    .nightstands-wrapper.shopingcard-wrapper.modal-page .total-wrapper {
        display: flex !important;
        grid-template-columns: 100%;
        grid-template-rows: auto;
        flex-wrap: wrap !important;
        flex-direction: column !important;
    }

    .calculate-form-production-date-container {
        display: flex;
    }


    .content-btn {
        padding: 17px 11px;
        font-size: 16px;
        letter-spacing: -0.5px;
        line-height: 16px;
        margin-top: 10px;
    }

    .total-content {
        display: flex;
        flex-wrap: wrap;
    }
}