.error-field-message{
    margin: 0.5rem;
    font-size: 14px;
    color: var(--red);
}

ul.error-field-message{
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

}