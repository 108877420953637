/* Основные стили для формы */
.bs-form {
    max-width:100%;
    margin: 0 auto;
}

/* Стили для каждой строки формы */
.bs-form-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

/* Базовый стиль для элементов формы */
.bs-form-item {
    padding: 5px;
    box-sizing: border-box;
}

/* Размеры колонок для разных экранов */
.bs-sm-12 {
    flex: 1 1 100%; /* На маленьких экранах колонка занимает всю ширину */
}

.bs-md-6 {
    flex: 1 1 50%; /* На средних экранах колонка занимает 50% ширины */
}

.bs-lg-4 {
    flex: 1 1 33.33%; /* На больших экранах колонка занимает 33.33% ширины */
}

/* Медиа-запросы для экранов sm, md, lg */
@media (max-width: 576px) {
    .bs-sm-12 {
        flex: 1 1 100%; /* На экранах меньше 576px колонка занимает 100% ширины */
    }

    .bs-md-6, .bs-lg-4 {
        flex: 1 1 100%; /* Убираем ограничения на md и lg, они становятся 100% ширины на маленьких экранах */
    }
}

@media (min-width: 577px) and (max-width: 767px) {
    .bs-md-6 {
        flex: 1 1 50%; /* На экранах от 577px до 767px колонка будет занимать 50% */
    }

    .bs-lg-4 {
        flex: 1 1 50%; /* На экранах от 577px до 767px колонка будет занимать 50% */
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .bs-lg-4 {
        flex: 1 1 33.33%; /* На экранах от 768px до 1024px колонка будет занимать 33.33% */
    }
}

@media (min-width: 1025px) {
    .bs-lg-4 {
        flex: 1 1 33.33%; /* На экранах больше 1024px колонка будет занимать 33.33% */
    }
}
