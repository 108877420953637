.form-check-field input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.form-check-field .form-check-label:before {
    content: '';
    display: block;
    width: 19px;
    flex: 0 0 19px;
    height: 19px;
    border: 1px solid #d9d8e1;
    border-radius: 5px;
    text-align: center;
}

.form-check-field input[type="checkbox"]:checked + .form-check-label:before {
    content: '✔';
}

.form-check-field .form-check-label {
    display: flex;
    gap: 5px;
    cursor: pointer;
}

.form-check-inline .form-check-field{
    width: max-content;
    max-width: 100%;
}
.form-check-inline {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 15px;
}