.hint-tooltip-message {
    position: relative;
    /*z-index: 99;*/
}
.hint-tooltip-message .hint-tooltip-header {
    position: relative;
    z-index: 0;
}
.hint-tooltip-message .hint-tooltip-content {
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background: #f0f0f3;
    left: 0;
    right: 0;
    padding: 10px 15px;
    max-width: 300px;
    width: max-content;
    border-radius: 4px;
    box-shadow: 0 0 5px #c2c2c2b3;
    z-index: 9;
    max-height: 350px;
    overflow-y: auto;
}

.hint-tooltip-message .hint-tooltip-content ul {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    list-style: circle;
    padding-left: 10px;
}
.hint-tooltip-message .hint-tooltip-content ul li{
    list-style: circle;
}