
.paz-svg-image-wrap {
    cursor: pointer;
    background: white;
    border-radius: 3px;
    transform: scale(1) translate(0%, 0%);
    padding: 0 !important;
    transition: transform .2s;
    border: 1px solid var(--border);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    width: max-content;
    max-width: 100%;
}

.paz-svg-image-wrap.paz-svg-scale-hover:hover {
    transform: scale(4) translate(0%, 20%);
}


.paz-picture-view.paz-svg-image-wrap {
    cursor: pointer;
    background: white;
    border-radius: 3px;
    transform: scale(1) translate(0%, 0%);
    padding: 0 !important;
    transition: transform .2s;
    width: 25px;
    border: 1px solid var(--border);
}

.paz-picture-view.paz-svg-image-wrap:hover {
    transform: scale(4) translate(-30%, -30%);
}
