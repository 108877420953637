
.frez-operations-container {
    display: grid;
    gap: 1rem;
}

.frez-operations-container .frez-picture-block {
    display: flex;
    align-items: center;
    justify-content: center;
}

.frez-operations-container .frez-picture-block img {
    max-width: 100%;
}

@media (min-width: 768px) {
    .frez-operations-container {
        grid-template-columns: 3fr 1fr;
        align-items: flex-start;
    }
}


.mdl-frez-move-types-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.mdl-frez-move-types-wrapper .frez-move-type-item {
    flex: 0 0 calc(50% - 10px);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.mdl-frez-move-types-wrapper .frez-move-type-item {
}

@media (min-width: 768px) {
    .mdl-frez-move-types-wrapper .frez-move-type-item {

        flex: 0 0 calc(100% / 4 - 12px);

    }
}

.frez-operations-container .label {
    font-size: 14px;
}
.frez-operations-container .field-with-limits{
    padding: 5px;
}

.frez-operations-container .form-input {
    padding: 10px 5px;
    height: revert;
}