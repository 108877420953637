.delivery-address-variant-item {
    border: 1px solid var(--border);
    border-radius: 3px;

    margin-bottom: 12px;
}
.delivery-address-variant__header ,
.delivery-address-variant__content{
    padding: 12px 12px;
}
.delivery-address-variant__content{
    border-top: 1px solid var(--border);
}
.delivery-address-variant__header .filed-radio {
    font-size: 14px;
    color: black;
    font-weight: 500;
}

.delivery-address-variant__header {
    position: relative;
    padding-right: 45px;
    /*display: flex;*/
    /*justify-content: space-between;*/
}
.delivery-address-variant__header .icon{
    position: absolute;
    right: 12px;
    top: 12px;
}