.user-address-views {
    display: grid;
    gap: 1em;
}

@media (min-width: 768px) {
    .user-address-views {
        grid-template-columns: repeat(2, calc(50% - 1rem));
    }
}

@media (min-width: 1440px) {
    .user-address-views {
        grid-template-columns: repeat(3, calc(33.3% - 1rem));
    }
}

