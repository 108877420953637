.field-with-limits {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    font-size: 14px;
    font-weight: 500;
    justify-content: space-between;
    padding: 5px 13px;
}

.field-with-limits .range-wrap,
.field-with-limits .value {
    flex: 1 1 auto;

}

.field-with-limits .range-wrap {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    font-size: 12px;
    font-weight: 400;
    color: var(--gray-violet)
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.label-help-wrap {
    position: relative;
}

.label-help-wrap .hint-tooltip-message {
    position: static;
}

.form-input.small {
    padding: .25rem .5rem;
    font-size: 12px;
    margin: 0;
    line-height: 1rem;
    height: 28px;
}


.react-select-default .custom__control {
    height: 51px;
    border-radius: 3px;
    border: 1px solid #d9d8e1;
}
.react-select-default.react-select-small .custom__control {
    height: 28px;
}

